import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-student-subscription",
  templateUrl: "./student-subscription.component.html",
  styleUrls: ["./student-subscription.component.css"],
})
export class StudentSubscriptionComponent implements OnInit {
  selectedSubjectID;
  selectedSubCourseID;
  expiryDate;
  filteredSubjects = [];
  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      edit: false,
      position: "right",
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',

      confirmSave: true,
    },
    editor: {},
    columns: {
      subcourseName: {
        editable: false,

        title: "Subcourse ",
      },
      subjectName: {
        editable: false,

        title: "Subject ",
      },

      expiry: {
        title: "Expiry",
      },
    },
  };
  tutorialViewsSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      edit: false,
      position: "right",
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',

      confirmSave: true,
    },
    editor: {},
    columns: {
      tutorialName: {
        title: "Tutorial ",
      },
      created_at: {
        title: "Viewed At ",
      },
    },
  };
  constructor(public dataService: DataService, public http: HttpClient) {}

  getFilteredSubjects() {
    this.http
      .get(this.dataService.serverUrl + "subject", {
        params: { subCourseID: this.selectedSubCourseID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED SUBJECT :", data);

        this.filteredSubjects = data.data;
      });
  }
  submit() {
    // alert(this.dataService.subscription.selectedStudentID)

    // alert(this.selectedSubjectID)
    // alert(this.expiryDate)

    this.http
      .post(this.dataService.serverUrl + "subscription", {
        subjectIDs: JSON.stringify([this.selectedSubjectID]),
        studentID: this.dataService.subscription.selectedStudentID,
        paymentID: "from panel",
        expiryDates: JSON.stringify([this.expiryDate]),
      })
      .subscribe(
        (data: any) => {
          console.log("POST SUBSCRIPTION", data);

          //REFRESH DATA
          this.dataService.getStudentSubjects();
        },
        (error) => {
          console.log("ERROR POST SUBSCRIPTION", error);
        }
      );
  }
  onDeleteConfirm(event) {
    this.http
      .delete(this.dataService.serverUrl + "subscription/" + event.data.id)
      .subscribe(
        (data: any) => {
          if (!data.status) {
            alert("Failed to Delete Record");
            return;
          }
          event.confirm.resolve();
        },
        (error) => {
          alert("Connectino err");
          console.log(error);
          event.confirm.reject();
        }
      );
  }
  deleteVideoView(event) {
    // console.log(event);

    // alert(event.data.id);
    this.http
      .delete(
        this.dataService.strapiServerUrl + "student-tutorials/" + event.data.id
      )
      .subscribe(
        (data: any) => {
          // if (!data.status) {
          //   alert("Failed to Delete Record");
          //   return;
          // }
          event.confirm.resolve();
        },
        (error) => {
          alert("Connectino err");
          console.log(error);
          event.confirm.reject();
        }
      );
  }
  ngOnInit() {}
}
