import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-add-chapter",
  templateUrl: "./add-chapter.component.html",
  styleUrls: ["./add-chapter.component.css"],
})
export class AddChapterComponent implements OnInit {
  selectedSubCourseID;
  selectedSubjectID;
  filteredSubjects = [];

  chapter;

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      edit: false,
      position: "right",
    },
    columns: {
      subCourseName: {
        title: "Subcourse ",
      },
      subjectName: {
        title: "Subject ",
      },
      name: {
        title: "Chapter ",
      },
    },
  };

  constructor(public http: HttpClient, public dataService: DataService) {}

  getFilteredSubjects() {
    this.http
      .get(this.dataService.serverUrl + "subject", {
        params: { subCourseID: this.selectedSubCourseID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED SUBJECT :", data);

        this.filteredSubjects = data.data;
      });
  }
  submitChapter() {
    // console.log("submitting");

    this.http
      .post(this.dataService.serverUrl + "chapter", {
        subjectID: this.selectedSubjectID + "",
        chapter: this.chapter + "",
      })
      .toPromise()
      .then((data: any) => {
        console.log(data);

        if (data.status) {
          this.dataService.getChapters();
        }
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  ngOnInit() {}

  onDeleteConfirm(event) {
    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "chapter/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            this.dataService.getChapters();
            event.confirm.resolve();
            return;
          }
          event.confirm.reject();

          alert("Unable to delete the course");
        })
        .catch((err) => {
          event.confirm.reject();

          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}
