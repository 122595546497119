import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/services/data.service";
import { StudentSubscriptionComponent } from "../student-subscription/student-subscription.component";
import { StudentSubscriptionEditButtonComponent } from "../student-subscription-edit-button/student-subscription-edit-button.component";
import { Cell } from "ng2-smart-table/ng2-smart-table";
import { ViewButtonComponent } from "./view-button/view-button.component";

@Component({
  selector: "app-student",
  templateUrl: "./student.component.html",
  styleUrls: ["./student.component.css"],
})
export class StudentComponent implements OnInit {
  students = [];

  studentSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: false,
    actions: {
      columnTitle: "",
      add: false,
      delete: false,
      edit: false,
      position: "right",
      // custom: [
      //   {
      //     name: "activate",
      //     title: '<i style="display:block" class="nb-checkmark"></i>',
      //   },
      // ],
    },
    columns: {
      name: {
        title: "Student Name",
      },
      phone: {
        title: "Phone",
      },
      parentPhone: {
        title: "Parents Phone",
      },
      wantCourse: {
        title: "Course Interested",
      },
      video_views: {
        title: "Views",
      },

      // city: {
      //   title: "City",
      // },
      // views: {
      //   filter: false,
      //   type: "custom",
      //   title: "",
      //   renderComponent: ViewButtonComponent,
      // },
      subscription: {
        filter: false,
        type: "custom",
        title: "",
        renderComponent: StudentSubscriptionEditButtonComponent,
      },
      // youtubeLink: {
      //   title: "Link",
      // },
      // sliderImage: {
      //   title: "Link Video",
      //   type: "custom",
      //   renderComponent: ViewVideoComponent,
      // },
    },
  };
  constructor(public http: HttpClient, public ds: DataService) {
    this.getStudents();
  }

  deleteData(abc) {}

  getStudents() {
    this.http.get(this.ds.serverUrl + "student").subscribe((data: any) => {
      console.log("GET STUDNETS ", data);
      this.students = data.data;
    });
  }
  ngOnInit() {}
}
