import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/services/data.service";
import { SliderThumbnailComponent } from "src/app/components/slider-thumbnail/slider-thumbnail.component";
import { SliderVideoComponent } from "src/app/components/slider-video/slider-video.component";
import { SliderImageTestimonialComponent } from "src/app/components/slider-image-testimonial/slider-image-testimonial.component";
import { RevisionNoteComponentComponent } from "src/app/components/revision-note-component/revision-note-component.component";
import { ViewLibraryPdfComponent } from '../view-library-pdf/view-library-pdf.component';
import { ViewVideoComponent } from '../view-video/view-video.component';

@Component({
  selector: "app-uploads",
  templateUrl: "./uploads.component.html",
  styleUrls: ["./uploads.component.css"],
})
export class UploadsComponent implements OnInit {
  pdfFile: FileList;
  selectedSubCourseID;
  selectedSubjectID;
  selectedChapterID;
  filteredSubjects = [];
  filteredChapters = [];
  pdfs = [];
  videos = [];
  pdfTitle;
  videoTitle = "";
  youtubeLink = "";
  noteTitle = "";
  fileToUpload;
  notes = [];

  slidersTestimonial = [];
  slidersVideo = [];

  pdfSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    // hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      subjectName: {
        title: "Subject Name",
      },
      chapterName: {
        title: "Chapter Name",
      },
      name: {
        title: "Pdf Title",
      },
      sliderImage: {
        title: "View Pdf",
        type: "custom",
        renderComponent: ViewLibraryPdfComponent,
      },
    },
  };
  videoSettings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    // hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      title: {
        title: "Video Title",
      },
      sliderImage: {
        title: "Link Video",
        type: "custom",
        renderComponent: ViewVideoComponent,
      },
    },
  };
  constructor(public http: HttpClient, public dataService: DataService) {
    this.getPdfs();
    this.getVideos()
  }

  pdfFileSelected(files: FileList) {
    this.pdfFile = files;
  }
  getFilteredSubjects() {
    this.http
      .get(this.dataService.serverUrl + "subject", {
        params: { subCourseID: this.selectedSubCourseID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED SUBJECT :", data);

        this.filteredSubjects = data.data;
      });
  }
  getFilteredChapters() {
    this.http
      .get(this.dataService.serverUrl + "chapter", {
        params: { subjectID: this.selectedSubjectID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED CHAPTER :", data);

        this.filteredChapters = data.data;
      });
  }

  ngOnInit() { }

  // postMethod(files: FileList) {
  uploadPdf() {
    console.log(this.pdfFile);

    this.fileToUpload = this.pdfFile.item(0);
    // this.fileToUpload = files.item(0);

    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    formData.append("pdfTitle", this.pdfTitle);
    formData.append("chapterID", this.selectedChapterID);

    this.http
      .post(this.dataService.serverUrl + "library", formData)
      .subscribe((val: any) => {
        console.log(val);

        if (!val.status) {
          alert("Unable to upload slider");
        } else {
          this.getPdfs();
        }
      });
    return false;
  }

  getPdfs() {
    this.http
      .get(this.dataService.serverUrl + "library")
      .toPromise()
      .then((data: any) => {
        console.log("GET LIBRARY", data);

        if (!data.status) {
          alert("Error getting sliders");
          return;
        }

        this.pdfs = data.data;
        // this.sliders = data.data;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  getVideos() {
    this.http
      .get(this.dataService.serverUrl + "video")
      .toPromise()
      .then((data: any) => {
        console.log("GET VIDEO", data);

        if (!data.status) {
          alert("Error getting sliders");
          return;
        }

        this.videos = data.data;

      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  addVideo() {
    // alert("asdf");
    this.http
      .post(this.dataService.serverUrl + "video", {
        chapterID: this.selectedChapterID,
        youtubeLink: this.youtubeLink,
        title: this.videoTitle,
      })
      .subscribe(
        (data: any) => {
          console.log("POST LIBRARY", data);
          this.getVideos()
        },
        (error) => {
          alert("Error Adding Video");
        }
      );
  }

  deleteData(event, endpoint = 'library') {



    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + `${endpoint}/` + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {

            switch (endpoint) {
              case 'library':
                this.getPdfs()
                break;
              case 'video':
                this.getVideos()
                break;

              default:
                break;
            }



            event.confirm.resolve();
            return;
          }
          event.confirm.reject();
          alert("Unable to delete the slider");
        })
        .catch((err) => {
          event.confirm.reject();
          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}
