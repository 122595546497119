import { Component, OnInit, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-get-questions',
  templateUrl: './get-questions.component.html',
  styleUrls: ['./get-questions.component.css']
})
export class GetQuestionsComponent implements OnInit {

  @Input() rowData: any;

  constructor(public dataService: DataService, public http: HttpClient) { }

  // @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    // this.renderValue = this.value.toString().toUpperCase();

    console.log('get questions component row data', this.rowData)

  }



  //   this.dataService.getQuestionsEvent.emit(this.rowData.id)


  getQuestions() {
    // alert(this.rowData.id)
    // this
    // this.dataService.report.testSelected = this.rowData.id
    // this.dataService.report.testSelected.emit(this.rowData.id)
    this.http.get(this.dataService.serverUrl + "solution?testID=" + this.rowData.id, {}).toPromise().then(data => {
      console.log(data);

      this.dataService.getQuestionsEvent.emit(data)


    }).catch(err => {
      alert("Error getting Solutions")
      console.log(err);

    })
  }
}
