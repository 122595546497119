import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-upload-solution-csv',
  templateUrl: './upload-solution-csv.component.html',
  styleUrls: ['./upload-solution-csv.component.css']
})
export class UploadSolutionCSVComponent implements OnInit {

  @ViewChild("uploadForm", { static: false }) uploadForm: ElementRef

  numberOfQuestions = 0
  @Input() rowData: any;


  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;


  constructor(public dataService: DataService, private fb: FormBuilder, public http: HttpClient) {
    console.log(this.rowData);


  }

  add() {



    this.http.post(
      this.dataService.serverUrl + "solution/numberOfQuestions", { numberOfQuestions: this.numberOfQuestions, testID: this.rowData.id }).subscribe((val: any) => {

        console.log(val);

        if (val.status) {
          alert("Question Added  !");
          return
        }
        alert("Error updating the question number !")
      }, (error => {

        alert("Error updating the question number")
        console.log(error);

      }));
    return false;
  }

  ngOnInit() {

  }



  // onFileChange(event) {
  //   let reader = new FileReader();
  //   if (event.target.files && event.target.files.length > 0) {
  //     let file = event.target.files[0];
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       this.form.get('avatar').setValue({
  //         filename: file.name,
  //         filetype: file.type,
  //         value: reader.result.split(',')[1]
  //       })
  //     };
  //   }
  // }

  // postMethod(files: FileList) {
  //   this.fileToUpload = files.item(0);
  //   let formData = new FormData();


  //   console.log(files);


  //   formData.append('file', this.fileToUpload, `${this.rowData.id}.csv`);
  //   // formData.append('abc', "def");

  //   // upload the csv solution
  //   this.http.post(this.dataService.serverUrl + "solution/upload", formData).subscribe((val: any) => {

  //     console.log(val);

  //     if (val.status) {
  //       alert("CSV Uploaded !");
  //       return
  //     }
  //     alert("Failed to Upload CSV  !")
  //   }, (error => {
  //     alert("Failed to Upload Error, Server Problem. Please contact the developer")
  //   }));
  //   return false;
  // }




  upload() {
    // alert("sadf")
    console.log(this.uploadForm);
    this.uploadForm.nativeElement.submit()

  }

}
