import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(public ds: DataService, public router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.ds.environment == "dev") return true;
    return this.ds.isAuthenticated().then((authenticated: boolean) => {
      if (authenticated) {
        return true;
      } else {
        // alert("false");
        this.router.navigate(["/"]);
        return false;
      }
    });
    // if (window.localStorage.get("login")) {
    //   alert(true);
    //   return true;
    // } else {
    //   alert(false);
    //   return false;
    // }
  }
}
