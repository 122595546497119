import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-download-report-button-component',
  templateUrl: './download-report-button-component.component.html',
  styleUrls: ['./download-report-button-component.component.css']
})
export class DownloadReportButtonComponentComponent implements OnInit {
  @Input() rowData: any;

  constructor(public http: HttpClient, public dataService: DataService) { }

  ngOnInit() {
  }

  download() {
    console.log(this.rowData);

    // alert("will download")
    window.open(this.dataService.serverUrl + `uploads/reports/${this.rowData.id}.pdf`, "_blank")


    // this.http.get(this.dataService.serverUrl + `uploads/reports/${this.rowData.report}`, { responseType: 'blob' })
    //   .toPromise().then(data => {
    //     console.log(data);

    //   }).catch(err => {
    //     alert("Error Download Report !")
    //     console.log(err);

    //   });


  }
}
