import { Component, OnInit, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-view-report-button-component',
  templateUrl: './view-report-button-component.component.html',
  styleUrls: ['./view-report-button-component.component.css']
})
export class ViewReportButtonComponentComponent implements OnInit {



  @Input() rowData: any;

  // @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    // this.renderValue = this.value.toString().toUpperCase();

    console.log(this.rowData)

  }


  constructor(public dataService: DataService) { }


  viewStudents() {
    // alert(this.rowData.id)
    // this
    // this.dataService.report.testSelected = this.rowData.id
    this.dataService.report.testSelected.emit(this.rowData.id)
  }
}
