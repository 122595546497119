import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";

import { Ng2SmartTableModule, LocalDataSource } from "ng2-smart-table";
import { ViewTestResultButtonComponent } from "src/app/app/test/view-test-result-button/view-test-result-button.component";
@Component({
  selector: "app-add-test",
  templateUrl: "./add-test.component.html",
  styleUrls: ["./add-test.component.css"],
})
export class AddTestComponent implements OnInit {
  title;
  positiveMarks;
  minutes;
  test;
  negativeMarks;
  numberOfQuestions;
  startDateTime: any;
  endDateTime;
  questions = [];
  questionsPositiveMarks = [];
  questionsNegativeMarks = [];
  filteredQuestions = [];

  selectedSubCourseID;

  selectedSubjectID;
  // selectedChapterID;
  filteredSubjects = [];
  filteredChapters = [];
  public tests = new LocalDataSource();

  textQuestion = "";
  firstOption = "";
  secondOption = "";
  thirdOption = "";
  fourthOption = "";
  correctOption;
  testFile;
  answerTestFile;

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      id: {
        title: "ID",
      },
      // subcourse: {
      //   title: "Subcourse",
      //   valuePrepareFunction: (data) => {
      //     return data.name
      //   }
      // },
      name: {
        title: "Test",
      },
      positiveMarks: {
        title: "Positive Marks",
      },
      minutes: {
        title: "Minutes",
      },
      negativeMarks: {
        title: "Negative Marks",
      },
      sliderImage: {
        title: "View Result",
        type: "custom",
        renderComponent: ViewTestResultButtonComponent,
      },
    },
  };

  public quizChapterQuestions = new LocalDataSource();

  // quizChapterQuestions = []

  constructor(public http: HttpClient, public dataService: DataService) {
    this.getTests();
  }

  ngOnInit() {}

  addQuestions() {
    this.questions = [];
    this.questionsPositiveMarks = [];
    this.questionsNegativeMarks = [];

    for (let i = 0; i < this.numberOfQuestions; i++) {
      // const element = array[i];
      this.questions.push([0, 0, 0, 0]);
      this.questionsPositiveMarks.push(this.positiveMarks || 0);
      this.questionsNegativeMarks.push(this.negativeMarks || 0);
    }
  }
  answerSelected(i, j) {
    // alert(i)
    // alert(j)

    if (this.questions[i][j] == j + 1) {
      this.questions[i][j] = 0;
    } else this.questions[i][j] = j + 1;
  }

  testPaperSelected(files: FileList) {
    // alert(files.item(0).type)
    if (files.item(0).type != "application/pdf") {
      return alert("Only Pdf is supported");
    }
    this.testFile = files;
  }
  answerPaperSelected(files: FileList) {
    // alert(files.item(0).type)
    if (files.item(0).type != "application/pdf") {
      return alert("Only Pdf is supported");
    }
    this.answerTestFile = files;
  }

  createTest() {
    console.log("questions", this.questions);
    // you will get array of array in  this.questions . remove all the zeros and send to backend

    let i = 0;
    this.filteredQuestions = [];
    this.questions.forEach((question) => {
      // i++
      this.filteredQuestions.push([]);

      question.forEach((option) => {
        if (option != 0) {
          this.filteredQuestions[i].push({ correct_option: option });
        }
      });

      i++;
    });

    console.log("filterest questions", this.filteredQuestions);
    this.submitTest();
  }

  deleteTest(event) {
    console.log(event);
    this.http
      .delete(this.dataService.serverUrl + "test/" + event.data.id)
      .subscribe((data) => {
        this.getTests();
      });
  }
  submitTest() {
    if (!this.selectedSubCourseID) {
      return;
    }
    let formData = new FormData();
    let fileToUpload;
    let answerFileToUpload;

    fileToUpload = this.testFile.item(0);
    answerFileToUpload = this.answerTestFile.item(0);
    // formData.append("subcourseID", this.selectedSubCourseID);
    formData.append("subjectID", this.selectedSubjectID);
    formData.append("testFile", fileToUpload, fileToUpload.name);
    formData.append(
      "testAnswerFile",
      answerFileToUpload,
      answerFileToUpload.name
    );
    formData.append("title", this.title);
    formData.append("positiveMarks", this.positiveMarks);
    formData.append("minutes", this.minutes);
    formData.append(
      "startDateTime",
      new Date(this.startDateTime).toISOString()
    );
    formData.append("endDateTime", new Date(this.endDateTime).toISOString());
    formData.append("negativeMarks", this.negativeMarks);
    formData.append("questions", JSON.stringify(this.filteredQuestions));
    formData.append(
      "questionsPositiveMarks",
      JSON.stringify(this.questionsPositiveMarks)
    );
    formData.append(
      "questionsNegativeMarks",
      JSON.stringify(this.questionsNegativeMarks)
    );

    this.http
      .post(this.dataService.serverUrl + "test", formData)
      .subscribe((val: any) => {
        console.log(val);

        if (!val.status) {
          alert("Unable to add question");
        } else {
          this.getTests();
          // this.getQuizChapterQuestion();
        }
      });
    return false;
  }

  getFilteredSubjects() {
    this.http
      .get(this.dataService.serverUrl + "subject", {
        params: { subCourseID: this.selectedSubCourseID },
      })
      .subscribe((data: any) => {
        console.log("GET FILTERED SUBJECT :", data);

        this.filteredSubjects = data.data;
      });
  }

  getTests() {
    this.tests.load([]);
    this.http
      .get(this.dataService.serverUrl + "test")
      .subscribe((data: any) => {
        console.log("GET TESTS :", data);

        this.tests.load(data.data);
        // this.quizChapterQuestions = data.data;
        // this.quizChapterQuestions.load(data.data)
      });
  }
}
