import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-slider-thumbnail',
  templateUrl: './slider-thumbnail.component.html',
  styleUrls: ['./slider-thumbnail.component.css']
})
export class SliderThumbnailComponent implements OnInit {
  url
  @Input() rowData: any;
  constructor(public dataService:DataService) {
this.url=this.dataService.serverUrl



  }

  ngOnInit() {
    console.log('rowdata',this.rowData);
  }

}
