import { Component, OnInit } from "@angular/core";

import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";
import { EventEmitter } from 'events';

@Component({
  selector: "app-city",
  templateUrl: "./city.component.html",
  styleUrls: ["./city.component.css"]
})
export class CityComponent implements OnInit {
  cities = this.dataService.cities;
  city = "";
  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    add: {
      addButtonContent: ""
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right"
    },
    columns: {
      city: {
        title: "City Name"
      }
    }
  };

  constructor(public http: HttpClient, public dataService: DataService) {
    this.getCities();
  }

  onDeleteConfirm(event) {
    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "city/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            this.getCities();

            event.confirm.resolve();
            return;
          }
          event.confirm.reject();
          alert("Unable to delete the city");
        })
        .catch(err => {
          event.confirm.reject();
          alert("Connection Error");
          console.log(JSON.stringify(err));
        });

    } else {

    }
  }

  getCities() {
    this.http
      .get(this.dataService.serverUrl + "city", {})
      .toPromise()
      .then((data: Array<any>) => {
        console.log(data);

        this.dataService.cities = data;

        // console.log(this.cities);
        this.cities = this.dataService.cities;



        this.dataService.citiesUpdated.emit()
      })
      .catch(err => {
        alert("Connection Error");
        console.log(err);
      });
  }

  submitCity() {
    console.log("submitting");

    this.http
      .post(this.dataService.serverUrl + "city", { city: this.city })
      .toPromise()
      .then((data: any) => {
        console.log(data);

        if (data.status === "success") {
          this.getCities();
        }
      })
      .catch(err => {
        alert("Connection Error");
        console.log(err);
      });
  }

  ngOnInit() { }
}
