import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slot-master",
  templateUrl: "./slot-master.component.html",
  styleUrls: ["./slot-master.component.css"]
})
export class SlotMasterComponent implements OnInit {
  cities = [];
  timeSlot;
  centerMapURL = "";
  centerLat = "";
  centerLng = "";
  selecteCityID;

  centers;

  selectedCourseID;
  courses;
  subject;
  subjects = [];

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    hideSubHeader: true,
    actions: {
      edit: false,
      position: "right"
    },
    columns: {
      city: {
        title: "City"
      },
      name: {
        title: "Course "
      }
    }
  };

  constructor() {}
  submit() {}
  onDeleteConfirm(event) {}
  ngOnInit() {}
}
