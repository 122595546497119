import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-view-button",
  templateUrl: "./view-button.component.html",
  styleUrls: ["./view-button.component.css"],
})
export class ViewButtonComponent implements OnInit {
  @Input("rowData") rowData;
  constructor(public dataService: DataService, public http: HttpClient) {}

  ngOnInit() {}
  view() {
    // alert(JSON.stringify(this.rowData));
    this.dataService.subscription.selectedStudentID = this.rowData.id;
    this.dataService.subscription.selectedStudentName = this.rowData.name;
    this.dataService.getStudentSubjects();
  }
}
