import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-course",
  templateUrl: "./course.component.html",
  styleUrls: ["./course.component.css"],
})
export class CourseComponent implements OnInit {
  course = "";
  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      name: {
        title: "Course Name",
      },
    },
  };

  constructor(public dataService: DataService, public http: HttpClient) {}

  ngOnInit() {}
  submitCourse() {
    this.http
      .post(this.dataService.serverUrl + "course", { course: this.course })
      .toPromise()
      .then((data: any) => {
        console.log("POST COURSE : ", data);

        if (data.status) {
          this.dataService.getCourses();
        }
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }

  onDeleteConfirm(event) {
    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "course/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            this.dataService.getCourses();
            event.confirm.resolve();

            return;
          }
          event.confirm.reject();

          alert("Unable to delete the course");
        })
        .catch((err) => {
          alert("Connection Error");
          event.confirm.reject();

          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}
