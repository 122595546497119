import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-student-subscription-edit-button",
  templateUrl: "./student-subscription-edit-button.component.html",
  styleUrls: ["./student-subscription-edit-button.component.css"],
})
export class StudentSubscriptionEditButtonComponent implements OnInit {
  @Input("rowData") rowData;
  constructor(public dataService: DataService, public http: HttpClient) {}

  ngOnInit() {}
  view() {
    // alert(JSON.stringify(this.rowData));
    this.dataService.subscription.selectedStudentID = this.rowData.id;
    this.dataService.subscription.selectedStudentName = this.rowData.name;
    this.dataService.getStudentSubjects();
    this.dataService.getStudentTutorialViews(this.rowData.id);
  }
}
