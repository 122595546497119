import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-slider-image",
  templateUrl: "./slider-image.component.html",
  styleUrls: ["./slider-image.component.css"],
})
export class SliderImageComponent implements OnInit {
  type = "image";
  url = this.dataService.serverUrl;
  @Input() rowData: any;

  constructor(public dataService: DataService) {}

  ngOnInit() {
    if (this.rowData.pdfName.includes(".pdf")) this.type = "pdf";
  }
}
