import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-center-master",
  templateUrl: "./center-master.component.html",
  styleUrls: ["./center-master.component.css"],
})
export class CenterMasterComponent implements OnInit {
  cities = [];
  centerName;
  centerMapURL = "";
  centerLat = "";
  centerLng = "";
  selecteCityID;

  centers;

  selectedCourseID;
  courses;
  subject;
  subjects = [];

  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: true,
    actions: {
      edit: false,
      position: "right",
    },
    columns: {
      city: {
        title: "City",
      },
      center: {
        title: "Center ",
      },
    },
  };

  constructor(public http: HttpClient, public dataService: DataService) {
    this.dataService.citiesUpdated.subscribe((data) => {
      // this.cities = this.dataService.cities
    });
    // this.cities = this.dataService.cities
    // this.getCities();
    this.getCenters();

    // console.log(this.dataService.courses);

    // this.dataService.coursesUpdated.subscribe(data => {
    //   this.courses = this.dataService.courses;
    // });
    this.courses = this.dataService.courses;
  }

  getCenters() {
    this.http
      .get(this.dataService.serverUrl + "center", {})
      .toPromise()
      .then((data: Array<any>) => {
        console.log(data);
        this.centers = data;

        // this.subjects = data;
        // // update the service courses
        // this.dataService.courses = data;
        // // emit the event coursesUpdate
        // this.dataService.coursesUpdated.emit();
        // console.log(this.dataService.courses);
        // this.courses = this.dataService.courses;
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }

  getCities() {
    // this.cities = this.dataService.cities;
    // console.log(this.dataService.cities);
    console.log(this.cities);
  }

  submit() {
    const regex = new RegExp("@(.*),(.*),");
    const latLngMatch = this.centerMapURL.match(regex);
    if (latLngMatch) {
      this.centerLat = latLngMatch[1];
      this.centerLng = latLngMatch[2];
    }

    console.log(latLngMatch);
    // console.log("submitting");

    this.http
      .post(this.dataService.serverUrl + "center", {
        cityID: this.selecteCityID + "",
        name: this.centerName + "",
        mapURL: this.centerMapURL + "",
      })
      .toPromise()
      .then((data: any) => {
        console.log(data);

        if (data.status) {
          this.getCenters();
        }
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }
  ngOnInit() {}

  onDeleteConfirm(event) {
    // alert(event.data.id);
    console.log("Delete Event In Console");
    console.log(event);
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "center/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            // this.getCourses();
            event.confirm.resolve();
            return;
          }
          event.confirm.reject();
          alert("Unable to delete the course");
        })
        .catch((err) => {
          event.confirm.reject();
          alert("Connection Error");
          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}
