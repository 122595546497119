import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import {
  NbSidebarModule,
  NbMenuModule,
  NbCardModule,
  NbCheckboxModule,
  NbInputModule,
  NbSpinnerModule,
} from "@nebular/theme";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { CityComponent } from "./pages/city/city.component";
import { CourseComponent } from "./pages/course/course.component";
import { SubjectComponent } from "./pages/subject/subject.component";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { MastersComponent } from "./masters/masters.component";
import { DataService } from "./services/data.service";
import { FormsModule } from "@angular/forms";
import { CityMasterComponent } from "./components/city-master/city-master.component";
import { SubjectMasterComponent } from "./components/subject-master/subject-master.component";
import { BookTestComponent } from "./components/book-test/book-test.component";
import { BookTestMasterComponent } from "./components/book-test-master/book-test-master.component";
import { ReportComponent } from "./pages/report/report.component";
import { SliderComponent } from "./pages/slider/slider.component";
import { ResultComponent } from "./pages/result/result.component";
import { VideoComponent } from "./pages/video/video.component";
import { CreateTestComponent } from "./pages/create-test/create-test.component";
import { UploadsComponent } from "./components/uploads/uploads.component";
import { AuthGuard } from "./services/auth.guard";
import { TestReportComponent } from "./pages/test-report/test-report.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "testreport/:testId",
    component: TestReportComponent,
  },
  {
    path: "dashboard",
    // canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: "masters",
        component: MastersComponent,
      },
      // {
      //   path: "subject",
      //   component: SubjectMasterComponent
      // },
      {
        path: "masters",
        // path: "testPrepare",
        component: CreateTestComponent,
      },
      {
        path: "book",
        component: BookTestMasterComponent,
      },
      // {
      //   path: "course",
      //   component: CourseComponent
      // },
      // {
      //   path: "subject",
      //   component: SubjectComponent
      // },
      {
        path: "report",
        component: ReportComponent,
      },
      // {
      //   path: "slider",
      //   component: SliderComponent,
      // },
      {
        path: "uploads",
        component: UploadsComponent,
      },
      {
        path: "result",
        component: ResultComponent,
      },
      // {
      //   path: 'video',
      //   component: VideoComponent
      // },
      {
        path: "solution",
        component: VideoComponent,
      },
      {
        path: "**",
        redirectTo: "uploads",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forRoot(routes),
    NbSidebarModule,
    NbMenuModule,
    NbCardModule,
    NbSpinnerModule,
    // NbTabsetModule,
    NbCheckboxModule,
    NbInputModule,
    Ng2SmartTableModule,
  ],
  declarations: [],

  exports: [RouterModule],
})
export class AppRoutingModule {}
