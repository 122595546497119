import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-sub-course",
  templateUrl: "./sub-course.component.html",
  styleUrls: ["./sub-course.component.css"],
})
export class SubCourseComponent implements OnInit {
  selectedCourseID;
  subCourse = "";
  settings = {
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      position: "right",
    },
    columns: {
      name: {
        title: "Course Name",
      },
    },
  };

  constructor(public dataService: DataService, public http: HttpClient) {}

  ngOnInit() {}
  submitSubCourse() {
    console.log("submitting");

    this.http
      .post(this.dataService.serverUrl + "subcourse", {
        subcourse: this.subCourse,
        courseID: this.selectedCourseID,
      })
      .toPromise()
      .then((data: any) => {
        console.log(data);

        if (data.status) {
          this.dataService.getSubCourses();
        }
      })
      .catch((err) => {
        alert("Connection Error");
        console.log(err);
      });
  }

  onDeleteConfirm(event) {
    if (window.confirm("Are you sure you want to delete?")) {
      this.http
        .delete(this.dataService.serverUrl + "subcourse/" + event.data.id, {})
        .toPromise()
        .then((data: any) => {
          console.log(data);

          if (data.status) {
            // alert("City Deleted !");
            this.dataService.getSubCourses();
            event.confirm.resolve();

            return;
          }
          event.confirm.reject();

          alert("Unable to delete the subcourse");
        })
        .catch((err) => {
          alert("Connection Error");
          event.confirm.reject();

          console.log(JSON.stringify(err));
        });
    } else {
    }
  }
}
